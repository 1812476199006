<template>
  <v-app class="advisor-app">
    <app-bar :is-app-layout="true" />
    <app-sidebar />
    <v-main :class="$route.params.org_id">
      <slot />
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import { useTheme } from "vuetify";
import { themes } from "@/themes";
import { setupAppConfiguration } from "@/utils/app-config";
import { useAppStore } from "~/store/app";
import { storeToRefs } from "pinia";

// 992 is min width of main screen in desktop mode as 256 is width of menu

const $route = useRoute();
const { $sentry, $setAppLocale } = useNuxtApp();
const {
  advisor: { themesEnabled },
} = useRuntimeConfig().public;

const { layoutSettings, user } = storeToRefs(useAppStore());
const { updateLayoutSettings, resetLayoutSettings } = useAppStore();

const favicon = computed(() => {
  return layoutSettings.value?.favicon;
});
const title = computed(() => {
  return layoutSettings.value?.name;
});

useHead({
  link: [
    {
      hid: "icon",
      rel: "icon",
      type: "image/x-icon",
      href: favicon.value,
    },
  ],
  titleTemplate: title.value,
});

watch(
  () => $route,
  (newVal) => {
    updateSentryContext(newVal);
  },
  {
    deep: true,
    immediate: true,
  },
);
watch(user, (newVal) => {
  $sentry.setUser({
    id: newVal?.id,
  });
});

resetLayoutSettings();
useTheme().global.name.value = "njordaCustomTheme";
if (themesEnabled && $route.params.org_id) {
  switch ($route.params.org_id) {
    case "avenirinvest":
      _setupAppConfiguration(themes.AVENIR_INVEST, "AVENIR_INVEST");
      break;
    case "njorda-white":
      _setupAppConfiguration(themes.NJORDA_MOCK_WHITE, "NJORDA_MOCK_WHITE");
      break;
    case "knoxcapital":
      _setupAppConfiguration(themes.KNOX_CAPITAL, "KNOX_CAPITAL");
      break;
    case "svp":
      _setupAppConfiguration(themes.SVP, "SVP");
      break;
    case "mm-test":
    case "mm":
      _setupAppConfiguration(themes.MAX_MATTHIESSEN, "MAX_MATTHIESSEN");
      break;
    default:
      break;
  }
}

updateSentryContext();
$sentry.setUser({
  id: user.value?.id,
});

function updateSentryContext(route = $route) {
  $sentry.setContext({
    path: route.fullPath,
    org: route.params?.org_id,
  });
}
function _setupAppConfiguration(appConfig, _theme) {
  const configuration = setupAppConfiguration(appConfig);
  useTheme().global.name.value = _theme;

  if (configuration.store.locale) {
    $setAppLocale(configuration.store.locale);
  }
  updateLayoutSettings({
    ...configuration.store,
  });
}
</script>
